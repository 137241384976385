// @import url('https://fonts.googleapis.com/css?family=Raleway:400, 700, 800');

*, *:after, *:before { -webkit-box-sizing: border-box; box-sizing: border-box; }

/* html {
  height: 100%;
}

body {
  height: 100%;
  // background: #444;
  font-family: 'Raleway', Arial, sans-serif;
} */

#app {
  height: 100%;
}

main a {
  color: #4e4a46;
  text-decoration: none;

  &:hover,
  &:focus {
    color: #c94e50;
  }
}

main {
  color: #fffce1;
  height: 100%;
  padding: 3em 2em;
  text-align: center;
  background: #b4bad2;
  overflow: auto;
}

h1 {
  font-weight: 800;
  font-size: 3.75em;
}

.description {
  max-width: 20em;
  margin: 1.2em auto 1em;
  line-height: 1.3em;
  font-weight: 400;
}

.demo-buttons {
  font-size: 1em;
  max-width: 1200px;
  margin: 2em auto 3em;
  padding: 0 10em;

  a {
    display: inline-block;
    margin: 0.75em;
    padding: 1.35em 1.1em;
    width: 15em;
    background: #fffce1;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 800;
    border-top-left-radius: 20px 50px;
    border-top-right-radius: 20px 50px;
    border-bottom-right-radius: 20px 50px;
    border-bottom-left-radius: 20px 50px;
    cursor: pointer;

    &.current-demo {
      background: #c94e50;
      color: #fffce1;
    }
  }
}

.side-button {
  display: inline-block;
  width: 5em;
  height: 2.5em;
  line-height: 2.5em;
  cursor: pointer;
  background: #fffce1;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.8em;
  font-weight: 800;

  &.left {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &.right {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &.active {
    background: #c94e50;
    color: #fffce1;
  }
}

@media screen and (max-width: 40em) {
  main {
    font-size: 80%;
  }

  h1 {
    padding-top: 1em;
    font-size: 2.5em;
  }

  .demo-buttons {
    max-width: 900px;
    padding: 0 2em;
  }
}

//
// Burger menu custom styles
//
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 15px;
  top: 25px;
  color: white;
}

@media screen and (max-width: 50em) {
  .bm-burger-button {
     top: 1%;
  }
}

// Outline for burger button focus state
.bm-burger-button button:focus {
  outline: 2px solid white;
  outline-offset: 8px;
}

// Background color for burger bars focus state
.bm-burger-button {
  button:focus + span {
    span.bm-burger-bars {
      background-color: white;
    }
  }
}

.right .bm-burger-button {
  left: initial;
  right: 36px;
}

.bm-burger-bars {
  background: #373a47;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-menu {
  background: #373a47;

  a {
    color: #b8b7ad;

    &:hover,
    &:focus {
      color: white;
    }
  }
}

.bm-item-list {
  padding: 0.8em;
  font-size: 1.25em;

  span {
    margin-left: 10px;
    font-weight: 700;
  }
}

.bm-item-list a {
  padding: 0.8em;
  font-size: 1.25em;

  span {
    margin-left: 10px;
    font-weight: 700;
  }
}

.bm-item:focus {
  outline: none;
}

//
// Mixins
//
.menu-1 {
  .bm-cross {
    background: #bdc3c7;
  }

  .bm-menu {
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }

  // Outline for cross button focus state
  .bm-cross-button button:focus {
    outline: 2px solid #c94e50;
    outline-offset: 2px;
  }

  // Background color for cross focus state
  .bm-cross-button {
    button:focus + span {
      span.bm-cross {
        background-color: #c94e50;
      }
    }
  }
}

.menu-2 {
  .bm-cross {
    background: #999;
  }

  .bm-menu {
    padding: 3.4em 1em 0;
  }

  a {
    padding: 1em;
  }

  i {
    font-size: 1.7em;
    vertical-align: middle;
    color: #282a35;
  }
  // Outline for cross button focus state
  .bm-cross-button button:focus {
    outline: 2px solid #c94e50;
    outline-offset: 2px;
  }

  // Background color for cross focus state
  .bm-cross-button {
    button:focus + span {
      span.bm-cross {
        background-color: #c94e50;
      }
    }
  }
}

.menu-3 {
  .bm-cross {
    background: #888;
  }

  .bm-menu {
    padding: 2em 1em;
    font-size: 1.15em;
  }

  i {
    opacity: 0.5;
  }

  span {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.75em;
  }
  // Outline for cross button focus state
  .bm-cross-button button:focus {
    outline: 2px solid #c94e50;
    outline-offset: 2px;
  }

  // Background color for cross focus state
  .bm-cross-button {
    button:focus + span {
      span.bm-cross {
        background-color: #c94e50;
      }
    }
  }
}

.menu-4 {
  .bm-cross {
    background: #888;
  }

  h2 {
    margin: 0 auto;
    padding: 2em 1em;
    color: rgba(0, 0, 0, 0.4);
    box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);

    i {
      margin-left: 0.2em;
    }

    span {
      font-size: 1.6em;
      font-weight: 700;
    }
  }

  a {
    padding: 1em;
    text-transform: uppercase;
    transition: background 0.3s, box-shadow 0.3s;
    box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);

    span {
      letter-spacing: 1px;
      font-weight: 400;
    }

    &:hover,
    &:focus {
      background: rgba(0, 0, 0, 0.2);
      box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
      color: #b8b7ad;
    }
  }
  // Outline for cross button focus state
  .bm-cross-button button:focus {
    outline: 2px solid #c94e50;
    outline-offset: 2px;
  }

  // Background color for cross focus state
  .bm-cross-button {
    button:focus + span {
      span.bm-cross {
        background-color: #c94e50;
      }
    }
  }
}

// #slide,
// #stack,
// #reveal {
//   .menu-1;
// }

// #bubble,
// #push {
//   .menu-2;
// }

// #elastic {
//   .menu-3;
// }

// #scaleDown,
// #scaleRotate,
// #pushRotate,
// #fallDown {
//   .menu-4;
// }

// Carbon ad

#carbonads * {
  margin: initial;
  padding: initial;
}

#carbonads {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
}

@media screen and (max-width: 40em) {
  #carbonads {
    left: 0;
    right: 0;
    margin: auto;
  }
}

@media screen and (max-width: 80em) {
  main {
    padding-bottom: 12em;
  }
}

#carbonads {
  position: fixed;
  bottom: 10px;
  right: 10px;
  display: flex;
  max-width: 330px;
  background-color: hsl(0, 0%, 98%);
  box-shadow: 0 1px 4px 1px hsla(0, 0%, 0%, 0.1);
  z-index: 100;
  border-radius: 5px;
}

#carbonads a {
  color: inherit;
  text-decoration: none;
}

#carbonads a:hover {
  color: inherit;
}

#carbonads span {
  position: relative;
  display: block;
  overflow: hidden;
}

#carbonads .carbon-wrap {
  display: flex;
  border-radius: 5px;
}

#carbonads .carbon-img {
  display: block;
  margin: 0;
  line-height: 1;
}

#carbonads .carbon-img img {
  display: block;
}

#carbonads .carbon-text {
  font-size: 13px;
  padding: 10px;
  margin-bottom: 16px;
  line-height: 1.5;
  text-align: left;
}

#carbonads .carbon-poweredby {
  display: block;
  padding: 6px 8px;
  background: #f1f1f2;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 600;
  font-size: 8px;
  line-height: 1;
  border-top-left-radius: 3px;
  position: absolute;
  bottom: 0;
  right: 0;
  border-bottom-right-radius: 5px;
}