body, html {
	font-family: 'Raleway', sans-serif;
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	color: #777;
	font-weight: 300;
	width: 100% !important;
	height: 100% !important;
}

h2 {
	margin: 0 0 20px 0;
	font-weight: 500;
	font-size: 34px;
	color: #333;
	text-transform: uppercase;
}
h3 {
	font-size: 22px;
	font-weight: 500;
	color: #333;
}
h4 {
	font-size: 24px;
	text-transform: uppercase;
	font-weight: 400;
	color: #333;
}
h5 {
	text-transform: uppercase;
	font-weight: 700;
	line-height: 20px;
}
p {
	font-size: 16px;
}
p.intro {
	margin: 12px 0 0;
	line-height: 24px;
}
a {
	color: #ff5c5c;
}
a:hover, a:focus {
	text-decoration: none;
	color: #222;
}
ul, ol {
	list-style: none;
}
.clearfix:after {
	visibility: hidden;
	display: block;
	font-size: 0;
	content: " ";
	clear: both;
	height: 0;
}
.clearfix {
	display: inline-block;
}
* html .clearfix {
	height: 1%;
}
.clearfix {
	display: block;
}
#map {
	margin:0 0 15px 0;
	padding-top:6px;
}
ul, ol {
	padding: 0;
	webkit-padding: 0;
	moz-padding: 0;
}
hr {
	height: 2px;
	width: 70px;
	text-align: center;
	position: relative;
	background: #ff5c5c;
	margin: 0;
	margin-bottom: 40px;
	border: 0;
}
.btn:active, .btn.active {
	background-image: none;
	outline: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}
a:focus, .btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn.active.focus {
	outline: none;
	outline-offset: none;
}
/* Navigation */
#menu {
	padding: 10px;
	transition: all 0.8s;
}
#menu.navbar-default {
	background-color: rgba(248, 248, 248, 0);
	border-color: rgba(231, 231, 231, 0);
}
#menu a.navbar-brand {
	font-family: 'Dancing Script', cursive;
	font-size: 36px;
	color: #ff5c5c;
	font-weight: 700;
	letter-spacing: 1px;
}
#menu.navbar-default .navbar-nav > li > a {
	text-transform: uppercase;
	color: #ddd;
	font-weight: 500;
	font-size: 15px;
	padding: 5px 0;
	border: 2px solid transparent;
	letter-spacing: 0.5px;
	margin: 10px 15px 0 15px;
}
#menu.navbar-default .navbar-nav > li > a:hover {
	color: #ff5c5c;
}
/* .on {
	background-color: #262626 !important;
	padding: 0 !important;
	padding: 10px 0 !important;
}
.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
	color: #ff5c5c !important;
	background-color: transparent;
}
.navbar-toggle {
	border-radius: 0;
}
.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
	background-color: #ff5c5c;
	border-color: #ff5c5c;
}
.navbar-default .navbar-toggle:hover>.icon-bar {
	background-color: #FFF;
} */

.navbar-default {
	background-color: #2c3e50;
	border-color: transparent;
  }

.navbar-default .navbar-brand {
	/* color: #fff;
	font-family: Stencil; */
	/* padding-left: 15%; */
	  font-family: 'Dancing Script', cursive;
	  font-size: 36px;
	font-size: 1.5em;
	  color: #ff5c5c;
	  font-weight: 700;
	  letter-spacing: 1px;
  }

  .navbar-default .navbar-brand:hover,
  .navbar-default .navbar-brand:focus,
  .navbar-default .navbar-brand:active,
  .navbar-default .navbar-brand.active {
	/* color: wwh grey; */
	color: red;
  }

  .navbar-default .navbar-collapse {
	border-color: rgba(255, 255, 255, 0.02);
  }

  .navbar-default .nav li a {
	font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
	text-transform: uppercase;
	font-weight: 400;
	letter-spacing: 1px;
	color: #fff;
  }

  .navbar-default .nav li a:hover,
  .navbar-default .nav li a:focus {
	color: #fff;
	outline: 0;
	text-decoration-line: underline;
  }

  .navbar-default .navbar-nav > .active > a {
	border-radius: 0;
	color: #fff;
	background-color: grey;
  }

  .navbar-default .navbar-nav > .active > a:hover,
  .navbar-default .navbar-nav > .active > a:focus {
	color: #fff;
	background-color: grey;
  }

  @media (min-width: 768px) {
	.navbar-default {
	  /* background-color: transparent; */
	  padding: 25px 0;
	  -webkit-transition: padding 0.3s;
	  -moz-transition: padding 0.3s;
	  transition: padding 0.3s;
	  border: 0;
	}

	.navbar-default .navbar-brand {
	  font-size: 5em;
	  -webkit-transition: all 0.3s;
	  -moz-transition: all 0.3s;
	  transition: all 0.3s;
	}

	.navbar-default .navbar-nav > .active > a {
	  border-radius: 3px;
	}

	.navbar-default.navbar-shrink {
	  background-color: #2c3e50;
	  padding: 10px 0;
	}

	.navbar-default.navbar-shrink .navbar-brand {
	  font-size: 1.5em;
	}

	.navbar-right .dropdown-menu {
	  right: 0;
	  left: auto;
	}
  }

.section-title {
	margin-bottom: 10px;
	padding: 60px 0;
}
.section-title .overlay {
	background: rgba(0, 0, 0, 0.7);
}
.section-title p {
	font-size: 22px;
	color: rgba(255,255,255,0.8);
}
.section-title hr {
	margin: 0 auto;
	margin-bottom: 40px;
}
.btn-custom {
	text-transform: uppercase;
	color: #fff;
	background-color: #ff5c5c;
	border: 0;
	padding: 14px 20px;
	margin: 0;
	font-size: 16px;
	font-weight: 500;
	letter-spacing: 0.5px;
	border-radius: 0;
	margin-top: 20px;
	transition: all 0.5s;
}
.btn-custom:hover, .btn-custom:focus, .btn-custom.focus, .btn-custom:active, .btn-custom.active {
	color: #fff;
	background-color: #b30000;
}
/* Header Section */
.intro {
	display: table;
	width: 100%;
	padding: 0px;
	background: url(../../public/img/crew-bg.jpg) no-repeat center center;
	background-color: #e5e5e5;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	-o-background-size: cover;
}
.intro .overlay {
	background: rgba(0,0,0,0.4);
}
.intro h1 {
	font-family: 'Dancing Script', cursive;
	color: #fff;
	font-size: 10em;
	font-weight: 700;
	margin-top: 0;
	margin-bottom: 10px;
}

.intro span {
	color: #a7c44c;
	font-weight: 600;
}
.intro p {
	color: #fff;
	font-size: 32px;
	font-weight: 300;
	margin-top: 10px;
	margin-bottom: 40px;
}

header .intro-text {
	padding-top: 250px;
	padding-bottom: 200px;
	text-align: center;
}

@media (max-width: 768px) {

	.intro h1 {
		font-family: 'Dancing Script', cursive;
		color: #fff;
		font-size: 4em;
		font-weight: 700;
		margin-top: 0;
		margin-bottom: 10px;
	}

	.intro p {
		color: #fff;
		font-size: 18px;
		font-weight: 300;
		margin-top: 10px;
		margin-bottom: 40px;

 }

}

/* About Section */
#about {
	padding: 80px 0;
	background: #2e5366;
	color: white;
}
#about h2 {
	color: white;
}
#about h3 {
	font-size: 20px;
}
#about .about-title {
   padding: 60px 0 40px 0;
}
#about .about-text {
	margin-left: 10px;
}
#about .about-logo {
	display:block;
  margin-left:auto;
  margin-right:auto;
}
#about .about-img {
	display:  inline-block;
	position: relative;
}
#about .about-img:before {
	display: block;
	content: '';
	position: absolute;
	top: 8px;
	right: 8px;
	bottom: 8px;
	left: 8px;
	border: 1px solid rgba(255, 255, 255, 0.5);
}
#about p {
	line-height: 24px;
	margin: 15px 0 30px;
}

/* Bebidas Section */
#bebidas {
	padding: 80px 0;
	background: #444 url(../../public/img/Drinks-bg.jpg) center center no-repeat fixed;
	background-size: cover;
}
#bebidas .section-title {
	/* background: #444 url(../../public/img/menu-bg.jpg) center center no-repeat fixed;
	background-size: cover; */
		padding: 65px 0 0 0;
}
#bebidas .section-title h2 {
	color: #fff;
}
#bebidas .overlay {
	/* padding: 0px 80px; */
	background: rgba(0, 0, 0, 0.7);
}
#bebidas h3 {
	padding: 10px 0;
	text-transform: uppercase;
}

/* Menu Section */

.menu{
  background:url(../../public/img/menu-bg.jpg) no-repeat center top scroll;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  overflow: hidden;
  padding-bottom:120px;
}

#restaurant-menu {
	padding: 80px 0;
	background: #444 url(../../public/img/menu-bg.jpg) center center no-repeat fixed;
	background-size: cover;
}
#restaurant-menu .section-title {
	/* background: #444 url(../../public/img/menu-bg.jpg) center center no-repeat fixed;
	background-size: cover; */
	padding: 65px 0 0 0;
}
#restaurant-menu .section-title h2 {
	color: #fff;
}
#restaurant-menu  .overlay {
	/* padding: 0px 80px; */
	background: rgba(0, 0, 0, 0.7);
}

#restaurant-menu h3 {
	padding: 10px 0;
	text-transform: uppercase;
}

/* Menu Section */

#restaurant-ementa .section-title {
	/* background: #444 url(../../public/img/menu-bg.jpg) center center no-repeat fixed;
	background-size: cover; */
	padding: 65px 0 0 0;
}
#restaurant-ementa .section-title h2 {
	color: #fff;
}
#restaurant-ementa  .overlay {
	/* padding: 0px 80px; */
	background: rgba(0, 0, 0, 0.7);
}

#restaurant-ementa img {
	width: 300px;
	box-shadow: 15px 0 #a7c44c;
}
#restaurant-ementa h3 {
	padding: 10px 0;
	text-transform: uppercase;
}

/* vinhos Section */
#vinhos {
	padding: 80px 0;
	background: #444 url(../../public/img/vinhos_bg.jpg) center top no-repeat fixed;
	background-size: cover;
}
#vinhos .section-title {
	/* background: #444 url(../../public/img/menu-bg.jpg) center center no-repeat fixed;
	background-size: cover; */
		padding: 65px 0 0 0;
}
#vinhos .section-title h2 {
	color: #fff;
}

#vinhos .overlay {
	background: rgba(0, 0, 0, 0.6);
}
#vinhos h2, #team p {
	color: #fff;
}
#vinhos h3 {
	color: #fff;
	font-weight: 400;
	font-size: 20px;
	margin: 5px 0;
}
#vinhos .thumbnail {
	background: transparent;
	border: 0;
}
#vinhos .thumbnail .vinhos-img {
	display: inline-flex;
	position: relative;
  /* height: 150px; */
}
#vinhos .regioes-img {
	display: inline-flex;
	position: relative;
	/* width: 200px;
  height: 400px; */
}
#vinhos .thumbnail .vinhos-img:before {
	display: inline-flex;
	content: '';
	position: absolute;
	top: 8px;
	right: 8px;
	bottom: 8px;
	left: 8px;
	border: 1px solid rgba(255, 255, 255, 0.2);
}
#vinhos .thumbnail .caption {
	padding-top: 10px;
}
#vinhos .thumbnail .caption p {
	color: rgba(255,255,255,0.7);
	padding: 0 10px;
	font-size: 15px;
}
#vinhos .thumbnail:hover {
    cursor: pointer;
    position: relative;
	background: rgba(0, 0, 0, 0.7);
	width: 160px;
}
.vinhos-modal-img {
	display: inline-block;
	position: relative;
    width: 5px;
    height: 5px;
}

.list_view .menu-section {
	margin: 0 20px 80px;
	/* background: #2e5366; */
}
.list_view .menu-section-title {
	font-size: 26px;
	display: block;
	font-weight: 500;
	color: white;
	margin: 20px 0;
	text-align: center;
}

.list_view .menu-item {
	margin: 15px 0;
	font-size: 18px;
	color: white;
	text-align: left;
}
.list_view .menu-item-name {
	font-weight: 600;
	font-size: 17px;
	color: white;
	border-bottom: 2px dotted rgb(213, 213, 213);
}

.list_view .menu-item-cover {
	font-family: 'Dancing Script', cursive;
	font-size: 72px;
	color: #74330D;
	font-weight: bold;
	letter-spacing: 1px;
}

.list_view .menu-item-description {
	font-style: italic;
	font-size: 15px;
}
.list_view .menu-item-price {
	float: right;
	font-weight: 600;
	color: white;
	margin-top: -26px;
}

.list_view .menu-section {
	margin: 0 20px 80px;
	/* background: #2e5366; */
}
.list_view .menu-section-title {
	font-size: 26px;
	display: block;
	font-weight: 500;
	color: white;
	margin: 20px 0;
	text-align: center;
}

.modal_list_view {
	margin: 15px 0;
	font-size: 18px;
	color: black;
	text-align: left;
}

.modal_list_view .menu-item {
	margin: 15px 0;
	font-size: 18px;
	color: black;
	/* text-align: left; */
	text-align: center;
}

.modal_list_view .menu-item-name {
	font-weight: 600;
	font-size: 17px;
	color: black;
  text-align: center;
}

.modal_list_view .menu-item-cover {
	font-family: 'Dancing Script', cursive;
	font-size: 72px;
	color: #74330D;
	font-weight: bold;
	letter-spacing: 1px;
}
.modal_list_view .menu-item-description {
	font-style: italic;
	font-size: 15px;
}
.modal_list_view .menu-item-price {
	/* float: right; */
	font-weight: 600;
	color: black;
	margin-top: -26px;
}

.modal_list_view .menu-section {
	margin: 0 20px 80px;
	/* background: #2e5366; */
}
.modal_list_view .menu-section-title {
	font-size: 26px;
	display: block;
	font-weight: 500;
	color:black;
	margin: 20px 0;
	text-align: center;
}

.collapse_section .rci-toggle:after {
    /* symbol for "opening" panels */
    font-family: 'Glyphicons Halflings'; /* essential for enabling glyphicon */
    content: "\e113"; /* adjust as needed, taken from bootstrap.css */
    float: right; /* adjust as needed */
    color: white; /* adjust as needed */
}

.collapse_section .rci-toggle.collapsed:after {
    /* symbol for "collapsed" panels */
    content: "\e114"; /* adjust as needed, taken from bootstrap.css */
}
.categories {
	padding-bottom: 30px;
	text-align: center;
}
ul.cat li {
	display: inline-block;
}
ol.type li {
	display: inline-block;
	margin: 0 10px;
	padding: 20px 0;
}
ol.type li a {
	color: #999;
	font-weight: 500;
	font-size: 14px;
	padding: 12px 24px;
	background: #eee;
	border: 0;
	border-radius: 0;
	text-transform: uppercase;
	letter-spacing: 0.5px;
}
ol.type li a.active {
	color: #fff;
	background-color: #ff5c5c;
}
ol.type li a:hover {
	color: #fff;
	background-color: #ff5c5c;
}
.isotope-item {
	z-index: 2
}
.isotope-hidden.isotope-item {
	z-index: 1
}
.isotope, .isotope .isotope-item {
	/* change duration value to whatever you like */
	-webkit-transition-duration: 0.8s;
	-moz-transition-duration: 0.8s;
	transition-duration: 0.8s;
}
.isotope-item {
	margin-right: -1px;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}
.isotope {
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transition-property: height, width;
	-moz-transition-property: height, width;
	transition-property: height, width;
}
.isotope .isotope-item {
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transition-property: -webkit-transform, opacity;
	-moz-transition-property: -moz-transform, opacity;
	transition-property: transform, opacity;
}
.hover-bg .hover-text {
	position: absolute;
	text-align: center;
	margin: 0 auto;
	color: #fff;
	background: rgba(0, 0, 0, 0.6);
	padding: 30% 0 0 0;
	height: 100%;
	width: 100%;
	opacity: 0;
	transition: all 0.5s;
}
.hover-bg .hover-text>h4 {
	opacity: 0;
	color: #fff;
	-webkit-transform: translateY(100%);
	transform: translateY(100%);
	transition: all 0.3s;
	font-size: 17px;
	letter-spacing: 0.5px;
	font-weight: 500;
}
.hover-bg:hover .hover-text>h4 {
	opacity: 1;
	-webkit-backface-visibility: hidden;
	-webkit-transform: translateY(0);
	transform: translateY(0);
}
.hover-bg:hover .hover-text {
	opacity: 1;
}

.scroll-lock {
  overflow:hidden;
  margin-right: 17px;
}

/* Contact Section */
#contact {
	padding: 100px 0 60px 0;
	background: #F6F6F6;
}
#contact .section-title p {
	color: #777;
}
#contact form {
	padding: 0;
}
#contact h3 {
	text-transform: uppercase;
	font-size: 20px;
	font-weight: 400;
	color: #555;
}
#contact .text-danger {
	color: #cc0033;
	text-align: left;
}
label {
	font-size: 12px;
	font-weight: 400;
	font-family: 'Open Sans', sans-serif;
	float: left;
}
#contact .form-control {
	display: block;
	width: 100%;
	padding: 6px 12px;
	font-size: 16px;
	line-height: 1.42857143;
	color: #444;
	background-color: #fff;
	background-image: none;
	border: 1px solid #ddd;
	border-radius: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-transition: none;
	-o-transition: none;
	transition: none;
}
#contact .form-control:focus {
	border-color: #999;
	outline: 0;
	-webkit-box-shadow: transparent;
	box-shadow: transparent;
}
.form-control::-webkit-input-placeholder {
color: #777;
}
.form-control:-moz-placeholder {
color: #777;
}
.form-control::-moz-placeholder {
color: #777;
}
.form-control:-ms-input-placeholder {
color: #777;
}
#contact .contact-item {
	margin: 20px 0 40px 0;
}
#contact .contact-item span {
	font-weight: 400;
	color: #aaa;
	text-transform: uppercase;
	margin-bottom: 6px;
	display: inline-block;
}
#contact .contact-item p {
	font-size: 16px;
}
/* Footer Section*/
#footer {
	background: #262626;
	padding: 50px 0 0 0;
}
#footer h3 {
	color: #ff5c5c;
	font-weight: 400;
	font-size: 18px;
	text-transform: uppercase;
	margin-bottom: 20px;
}

#footer .copyrights {
	padding: 20px 0;
	margin-top: 50px;
	background: #2e5366;
}

#footer .social {
	margin: 20px 0 30px 0;
}
#footer .social ul li {
	display: inline-block;
	margin: 0 20px;
}
#footer .social i.fa {
	font-size: 26px;
	padding: 4px;
	color: #fff;
	transition: all 0.3s;
}
#footer .social i.fa:hover {
	color: #eee;
}
#footer p {
	font-size: 15px;
	color: rgba(255,255,255,0.8)
}
#footer a {
	color: #f6f6f6;
}
#footer a:hover {
	color: #333;
}


/*
/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active, .accordion:hover {
  background-color: #ccc;
}

/* Style the accordion panel. Note: hidden by default */
.panel {
  padding: 0 18px;
  background-color: white;
  display: none;
  overflow: hidden;
}

.rci-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 12px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  /* background-color: #ff5c5c; */
  background-color: #98672C;
  border: none;
  border-radius: 15px;
  box-shadow: 0 5px #999;
}

.rci-button:hover {background-color: #74330D}

.rci-button:active {
  background-color: #98672C;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.page_container {
	padding-bottom:40px;
	background:#FFF;
}

@media (max-width: 767px) {
  /* #about  {
		padding: 20px;
	} */
	#about .about-title {
	   padding: 20px 0 20px 0;
	}
	.list_view .menu-item {
		margin: 14px 0;
		font-size: 14px;
		color: white;
		text-align: left;
	}
	.list_view .menu-item-name {
		font-weight: 600;
		font-size: 14px;
		color: white;
	}
	#vinhos .thumbnail .vinhos-img {
		display: inline-flex;
		position: relative;
    /* width: 120px;
    height: 200px; */
  }
}

@media (max-width: 479px) {

	/* #about  {
		padding: 5px 0;
	} */
	.list_view .menu-item {
		margin: 14px 0;
		font-size: 14px;
		color: white;
		text-align: left;
	}
	.list_view .menu-item-name {
		font-weight: 600;
		font-size: 12px;
		color: white;
	}
	#vinhos .thumbnail .vinhos-img {
		display: inline-flex;
		/* position: relative;
    width: 120px;
    height: 200px; */
  }

}
